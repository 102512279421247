@media print {
    @page {
        size: auto;
    }

    html, body {
        min-height: auto;
        height: auto!important;
    }

    html, p, a {
        -webkit-text-stroke: 0px!important;
    }

    .nav-accent-extra {
        display: none;
    }

    .navbar-brand {
        margin-right: 20px;
    }
    .app-name {
        margin-left: 10px;
    }

    a:not(.kitchen-manager), a:not(.kitchen-manager):visited, a:not(.kitchen-manager):hover, a:not(.kitchen-manager):focus {
        color: #212529;
        font-size: .875rem;
        font-weight: 500;
        text-decoration: none!important;
    }

    nav, footer, .share-section, .product-controls, .badge-section {
        display: none;
    }
    
    .measurement-controls {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed #C9C9C9;
    }

    .measurement-controls .measurement-system h4,.measurement-controls .measurement-system .h2 {
        margin-bottom: 10px;
    }

    .ingredients-section, .directions-section, .related-videos-section, .nutrition-facts-section {
        margin-bottom: 10px;
    }

    .measurement-bottom {
        padding-top: 9px;
        padding-bottom: 5px;
    }

    .sub-header {
        height: 20px;
    }

    .menu-section .ingredients-list {
        margin-bottom: 10px;
    }

    h3 {
        font-size: 1.6875rem;
        margin-bottom: 1px;
    }

    .ingredients-list.menu .header h3 {
        margin-top: 5px;
        margin-bottom: 1px;
    }

    .header-section {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .directions-section li {
        padding-bottom: 0px;
    }

    .measurement-bottom .measurement-group {
        margin-bottom: -10px !important;
    }
  
}