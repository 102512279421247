/* Load all images on load */
/* Use this for things that SHOULD NOT be lazy loaded by the browser */
body::after {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url('../images/components/Checkbox-Unselected-Normal@2x.png') url('../images/components/Checkbox-Unselected-Focused@2x.png') url('../images/components/Checkbox-Unselected-Disabled@2x.png') url('../images/components/Checkbox-Selected-Normal@2x.png') url('../images/components/Checkbox-Selected-Focused@2x.png') url('../images/components/Checkbox-Selected-Disabled@2x.png') url('../images/components/Radiobutton-Unselected-Normal@2x.png') url('../images/components/Radiobutton-Unselected-Focused@2x.png') url('../images/components/Radiobutton-Unselected-Disabled@2x.png') url('../images/components/Radiobutton-Selected-Normal@2x.png') url('../images/components/Radiobutton-Selected-Focused@2x.png') url('../images/components/Radiobutton-Selected-Disabled@2x.png') url('../images/style/Icon-Caret-Down@2x.png') url("../images/style/Icon-Sort@2x.png");
}