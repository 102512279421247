.header-section {
    margin-bottom: 40px;
}

.header-section .sub-header > div,
.header-section .sub-header p {
    display: inline-block;
    margin-bottom: 0;
}
.header-section .sub-header.public-facing-subheader .vertical-divider {
    margin-bottom: 7px;
}


.header-section .sub-header p {
    font-size: .813rem;
}

.book-section {
    margin-bottom: 45px;
    text-align: center;
}

.book-section .row {
    margin-left: 0;
    margin-right: 0;
}

.book-section .featured-img img {
    width: 100%;
    max-width: 304px;
}

.book-section .featured-info p {
    margin-bottom: 0;
    line-height: 19px;
}

.book-section .featured-info .publish-info {
    margin-top: 20px;
    margin-bottom: 50px;
}

.book-section .featured-info .purchase-link {
	height: 45px;
	background: url('../../assets/images/style/Icon-Chevron-Right-lg.png') #d2db0e no-repeat 95% center;
	display: block;
	line-height: 45px;
	text-align: center;
	margin-top: 125px;
	margin-bottom: 20px;
	margin-right: 15px;
	font-size: .85rem;
	color: #000000;
	border-radius: 5px;
}

.book-section .featured-info p {
    font-size: .875rem;
    margin-bottom: 0px;
}

.book-section .featured-info .purchase-cta button {
    position: relative;
    width: 100%;
    max-width: 280px;
    margin-bottom: 20px;
}

.book-section .featured-info .purchase-cta button img {
    position: absolute;
    top: 12px;
    right: 12px;
}

.overview-section {
    margin-bottom: 50px;
}

.overview-section h3 {
    margin-bottom: 20px;
}

.recipes-section {
    margin-top: 45px;
}

.recipes-section a {
    font-family: "OpenSans";
    font-weight: 500;
    font-size: .875rem;
    line-height: 19px;
    margin-bottom: 18px;
}

.recipes-section {
    margin-bottom: 50px;
}

.recipes-section .chapter>div {
    margin-bottom: 18px;
}

.recipes-section .line {
    margin-top: 10px;
    margin-bottom: 30px
}

@media (min-width: 768px) {
    .book-section {
        text-align: initial;
    }
    .book-section .featured-info .publish-info {
        margin-top: 37px;
        margin-bottom: 130px;
    }
    .book-section .featured-img {
        border-right: 1px dashed #C9C9C9;
        padding-right: 30px;
    }
    .book-section .featured-info {
        padding-left: 35px;
        padding-right: 35px;
    }
}