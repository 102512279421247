.spinner-component {
    margin: 100px auto;
    width: 200px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #F5F5F5;
}

.spinner,
.spinner-component p {
    display: inline-block;
}

.spinner-component p {
    color: #252525;
    font-family: 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: 0.875em;
    margin-bottom: 0;
    margin-left: 5px;
}