.btn-lg-basic {
    box-sizing: border-box;
    height: 43px;
    width: 153px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.btn-lg-basic:hover, .btn-lg-basic:active, .btn-lg-basic.selected {
    box-sizing: border-box;
    height: 43px;
    width: 153px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #F5F5F5;
}

.btn-lg-basic:disabled {
    border: 1px solid #E9E9E9;
    color: #C7C7C7;
}

.btn-sm-basic {
    box-sizing: border-box;
    height: 35px;
    width: 153px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.btn-sm-basic:hover, .btn-sm-basic:active, .btn-sm-basic.selected {
    box-sizing: border-box;
    height: 35px;
    width: 153px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #F5F5F5;
}

.btn-sm-basic:disabled {
    border: 1px solid #E9E9E9;
    color: #C7C7C7;
}