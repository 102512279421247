.product-controls .dashed-line {
    margin-top: 18px;
}

.info-section {
    margin-top: 45px;
    margin-bottom: 25px;
}

.info-section .name textarea {
    width: 100%;
    resize: none;
    border-radius: 4px;
    border: 1px solid #C7C7C7;
    height: 101px;
    font-size: 0.875rem;
}

.info-section h3 {
    margin-bottom: 35px;
}

.info-section .name {
    margin-bottom: 40px;
}

.info-section input {
    font-size: 0.75rem;
}
.info-section .event-details {
    margin-bottom: 10px;
}

.info-section .event-details h4 {
    margin-bottom: 15px;
}

.info-section .event-details p:not(.error), .info-section .location p {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: .813rem;
}

.info-section .event-details .guests input {
    width: 56px;
}

.info-section .event-details .time select, .info-section .event-details .time .dropdown {
    display: inline-block!important;
}

.info-section .event-details .time select {
    width: 45px;
    height: 33px;
    border: none;
    text-indent: 10px;
    margin-bottom: 15px;
}

.info-section .event-details .time select option {
    text-indent: 10px;
}

.info-section .event-details select::-ms-expand {
    display: none;
}

.info-section .event-details select {
    font-size: .75rem;
    background: url('../../assets/images/style/Icon-Caret-Down@2x.png') no-repeat right #ddd;
    background-position-x: 35px;
    background-size: 10px 10px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.info-section .event-details .date input[type=date],
.info-section .event-details .date input[type=text] {
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    height: 33px;
    width: 160px;
    font-size: .75rem;
    text-indent: 21px;
    text-transform: uppercase;
}


.info-section .event-details .date input[type=text]::placeholder {
    font-size: .75rem;
    text-indent: 32px;
    color: black!important;
    text-transform: uppercase;
}

.info-section .event-details .date {
    position: relative;
}

.info-section .event-details .date .calendar-icon {
    position: absolute;
    top: 35px;
    left: 25px;
}

.info-section .event-details .date input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.info-section .event-details .date input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 8px;
    width: 10px;
    height: 10px;
    background-color: transparent;
}

.info-section .event-details .meridiem {
    font-weight: 600;
    color: #005A70;
}

.info-section .location input {
    width: 100%;
}

/* Directions SEction */

.directions-section {
    margin-bottom: 45px;
}

/* Checkbox Collections */

/* bootstrap forces padding and this messes up the overlayes checkbox image */

.categories-section {
    margin-bottom: 70px;
}

.categories-section h3 {
    margin-bottom: 45px;
}

.categories-section .custom-checkbox {
    padding-left: 0!important;
    padding-right: 0!important;
}

.categories-section .row {
    margin-left: 0;
    margin-right: 0;
}

.categories-section .checkbox-group {
    margin-bottom: 15px;
}

/* MOZILLA ONLY */

@-moz-document url-prefix() {
    .info-section .event-details .time select {
        text-indent: 3px;
    }
    .info-section .event-details .date input[type="date"] {
        text-indent: 34px;
    }
}

/* Android Only */ 


.on-device.device-android .info-section .event-details .date input[type="date"]  {
    text-indent: 30px;

}

/* iPhone Only */

@media only screen and (max-device-width: 480px) {
    /* CSS overrides for mobile here */
    .info-section .event-details .date input[type="date"] {
        padding-top: 5px;
        text-indent: 30px;
        height: 38px;
    }
    .info-section .event-details .date .calendar-icon {
        top: 38px;
    }

    /* .info-section .event-details .date .calendar-icon {
        position: absolute;
        top: 38px;
        left: 25px;
    } */
}

/* Safari Only */

@media not all and (min-resolution:.001dpcm) {
    @media {
        .info-section .event-details .date input[type="date"] {
            text-indent: 35px;
        }
    }
}

/* IE ONLY  */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .info-section .event-details .date input[type=date] {
        text-indent: 32px;
    }
}