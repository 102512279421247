/**
    Cross browser CSS

*/

/* Bootstrap Modal inputs don't line up on safari - This adds to bootstrap's modal class applied to the bodys */

.modal-open {
    position: fixed;
    width: 100%;
}

.modal-open .modal {
    -webkit-overflow-scrolling: touch;
}

/* Chrome 29+ */

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    /* kitchen manager in nav no lining up correctly */
    a.kitchen-manager, a.kitchen-manager:hover, a.kitchen-manager:focus, a.kitchen-manager:visited {
        margin-top: 3px!important;
    }
}

/* Firefox - Any */

@-moz-document url-prefix() {
    a.kitchen-manager, a.kitchen-manager:hover, a.kitchen-manager:focus, a.kitchen-manager:visited {
        margin-top: 1px!important;
    }
    .dropdown-content a:focus {
        outline: 0;
        box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    }

}