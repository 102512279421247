/* 
• The Top frame:   Stroke: 1 , Color:  F5F5F5     |    Shadow: @ 37% Opacity  : 1x / 1y Spread 0 
•  The Middle frame:  Stroke: 1 , Color:  F5F5F5     |    Shadow: @ 15% Opacity  : 1x / 1y Spread 0
•  The bottom frame:  Stroke: 1 , Color:  F5F5F5     |    Shadow: @ 7% Opacity  : 1x / 1y Spread 0 
*/

.picture-frame-img {
    position: relative;
}

.picture-frame-img .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 10px);
    padding-top: calc(100% - 10px);
    /* match to width for 1:1 aspect ratio */
    border: 1px solid #F5F5F5;
    background-color: #FFFFFF;
    z-index: 3;
}

.picture-frame-img .frame-1 {
    position: relative;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.19);
}

.picture-frame-img .frame-2 {
    top: 3px;
    left: 3px;
    z-index: 2;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.15);
}

.picture-frame-img .frame-3 {
    top: 6px;
    left: 6px;
    z-index: 1;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.07);
}

.picture-frame-img .frame-1 .img-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 7px;
}

.picture-frame-img .frame-1 .img-container .frame-img {
    width: 192px;
    height: 192px;
}

@media only screen and (min-width: 768px) {
    .picture-frame-img .frame-1 .img-container .frame-img {
        width: 262px;
        height: 262px;
    }
}