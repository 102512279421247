/* Primary */

.digital-pearon-blue {
    color: #047a9c!important;
}

.ink-blue {
    color: #005a70!important;
}

.white {
    color: #ffffff!important;
}

.charcoal {
    color: #252525!important;
}

.charcoal-lite {
    color: #333333!important;
}

.medium-gray {
    color: #6a7070!important;
}

/* Secondary */

.digital-ice-blue {
    color: #047a9c!important;
}

.lime-green {
    color: #d2db0e!important;
}

.lime-green-dark {
    color: #c0d100!important;
}

.strawberry-red {
    color: #db0020!important;
}

.concrete {
    color: #c7c7c7!important;
}

.alto {
    color: #d9d9d9!important;
}

.moonlight {
    color: #e9e9e9!important;
}

.white-gray {
    color: #f5f5f5!important;
}

.white-gray-lite {
    color: #fbfbfb!important;
}

.digital-pearon-blue-bg {
    background-color: #047a9c!important;
}

.ink-blue-bg {
    background-color: #005a70!important;
}

.white-bg {
    background-color: #ffffff!important;
}

.charcoal-bg {
    background-color: #252525!important;
}

.charcoal-lite-bg {
    background-color: #333333!important;
}

.medium-gray-bg {
    background-color: #6a7070!important;
}

/* Secondary */

.digital-ice-blue-bg {
    background-color: #047a9c!important;
}

.lime-green-bg {
    background-color: #d2db0e!important;
}

.lime-green-dark-bg {
    background-color: #c0d100!important;
}

.strawberry-red-bg {
    background-color: #db0020!important;
}

.concrete-bg {
    background-color: #c7c7c7!important;
}

.alto-bg {
    background-color: #d9d9d9!important;
}

.moonlight-bg {
    background-color: #e9e9e9!important;
}

.white-gray-bg {
    background-color: #f5f5f5!important;
}

.white-gray-lite-bg {
    background-color: #fbfbfb!important;
}