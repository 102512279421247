.book-card.card .card-img {
    border: 1px solid #f2f2f2;
    border-radius: 0;
    width: 223px;
    height: 257px;
    margin: 0 auto;
    padding: 0 10px;
}

.book-card.card {
    padding: 0;
    height: auto;
    width: 221px;
    border: 1px solid transparent;
}

.book-card.card .title {
    color: #005A70!important;
    border-bottom: 1px solid transparent;
    display: inline-block;
    /* book title is a link */
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .844rem;
    font-weight: 600;
    line-height: 18px;
}