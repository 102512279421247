body:not(.tiny-mce-custom) {
    position: relative;
}
body * {
    background-clip: border-box!important;
}

html:not(.tiny-mce-custom), body:not(.tiny-mce-custom) {
    margin: 0; 
    height: 100%; 
    min-height:100%;
    /* overflow:auto; */
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    position: relative;
}

#wrapper {
    height: auto;
    min-height: 100%;
    position: relative;
}
h1, h2, h3, h4,.h2 {
    font-family: "Playfair Display";
    margin-top: 0;
    color: #005A70;
}

h1 {
    font-size: 2.25rem;
    margin-bottom: 30px;
}

h2 {
    font-size: 1.813rem;
    margin-bottom: 30px;
}

h3 {
    font-size: 1.6875rem;
    margin-bottom: 25px;
}

h4,.h2 {
    font-size: 1.188rem;
    margin-bottom: 20px;
}

p {
    font-family: 'OpenSans';
    font-size: .938rem;
    line-height: 28px;
}

a, a:hover, a:visited, a:focus {
    color: #005A70;
    font-family: "OpenSans";
    font-size: .75rem;
    font-weight: 600;
}

ul {
    padding-left: 47px;
}

ul li {
    list-style: none;
    list-style-position: inside;
    position: relative;
    font-family: "OpenSans";
    font-size: .938rem;
    line-height: 28px;
}

.no-bullets ul li {
    list-style: circle;
}

ul li:before {
    content: '•';
    vertical-align: middle;
    display: inline-block;
    margin-top: -3px;
    font-size: .875rem;
    position: absolute;
    left: -27px;
    top: 4px;
    /* margin-right: 20px; */
}

input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid transparent;
    line-height: normal;
}

/* Fix for iPAD having barely clickable checkboxes and labels */
/* label > * {
  pointer-events:none;
} */
label { cursor: pointer; }
label, input {
    touch-action: manipulation;
}
input, input:before, input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
   } 