.btn-lg-primary {
    height: 42px;
    width: 152px;
    border-radius: 4px;
    background-color: #D2DB0E;
}

.btn-lg-primary:hover, .btn-lg-primary:active, .btn-lg-primary.selected {
    height: 42px;
    width: 152px;
    border-radius: 4px;
    background-color: #C4D600;
}

.btn-lg-primary:disabled {
    background-color: transparent;
}

.btn-sm-primary {
    height: 34px;
    width: 152px;
    border-radius: 4px;
    background-color: #D2DB0E;
}

.btn-sm-primary:hover, .btn-sm-primary:active, .btn-sm-primary.selected {
    height: 34px;
    width: 152px;
    border-radius: 4px;
    background-color: #C4D600;
}

.btn-sm-primary:disabled {
    background-color: transparent;
}