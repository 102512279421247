.btn {
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
}

.btn-lg {
    font-size: .875rem;
    line-height: 19px;
}

.btn-sm {
    font-size: .75rem;
    line-height: 16px;
    padding: 0.3rem 0.5em 0.25rem 0.5em;
}