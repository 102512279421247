/* Cannot use bootstrap 4 - reverting to Bootstrap 3 float technique */

.checklist-collection {
    overflow-y: initial;
}

.checklist-collection .custom-checkbox {
    display: inline-block;
    float: left;
    width: 50%;
}

@media (min-width: 576px) {
    .checklist-collection .custom-checkbox {
        display: inline-block;
        float: left;
        width: 33.33%;
    }
}

@media (min-width: 992px) {
    .checklist-collection .custom-checkbox {
        display: inline-block;
        float: left;
        width: 100%;
    }
    .checklist-collection {
        max-height: 272px;
        overflow-y: auto;
    }
}

.checklist-collection::after {
    content: '';
    display: block;
    clear: both;
}