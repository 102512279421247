.collapse-ctrl {
    border: 1px solid #E9E9E9;
    height: 45px;
    padding: 0 20px;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* Center vertically */
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.collapse-ctrl p {
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}

.collapse-ctrl img, .collapse-ctrl p {
    display: inline-block;
}

.collapse-ctrl img {
    margin-left: auto;
    width: 12px;
}

.collapse-content {
    padding: 10px 20px;
    border: 1px solid #E9E9E9;
}