.library-component {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.library-component .left-section, .library-component .right-section {
    float: left;
}

/* LEFT SECTION */

.library-component .left-section {
    width: 100%;
    border-right: none;
    padding-bottom: 0;
}

/* CREATE RECIPE SECTION */

.library-component .create-recipe-section {
    height: 54px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    background-color: #FBFBFB;
    border-bottom: 1px solid #E9E9E9;
}

.library-component .create-recipe-section button {
    width: 190px;
}

/* RIGHT SECTION */

.library-component .right-section {
    width: 100%;
    padding: 20px 15px 50px 15px;
}

.library-component .right-section .controls {
    text-align: center;
}

.library-component .right-section .controls.dynamic-margin {
    margin-bottom: 20px;
}
.library-component .right-section .controls {
    margin-bottom: 0px;
}



.library-component .right-section .filter-badge-collection {
    margin-left: -15px;
    width: calc(100% + 30px);
    padding-left: 15px;
}


.library-component .right-section .filter-toggle {
    margin: 10px 0 0 0;
}

@media (min-width: 768px) {
    .library-component .right-section .controls {
        text-align: left;
    }
    .library-component .right-section .filter-badge-collection {
        margin-left: 0px;
        width: 100%;
        padding-left: 00px;
    }
}

@media (min-width: 992px) {
    .library-component {
        flex-direction: row;
    }
    /* LEFT SECTION */
    .library-component .left-section {
        width: 229px;
        display: inline-block;
        border-right: 2px solid #E9E9E9;
    }
    /* CREATE RECIPE SECTION */
    .library-component .create-recipe-section {
        height: 84px;
        text-align: center;
        padding-top: 10px;
        background-color: #FBFBFB;
        border-bottom: 1px solid transparent;
    }
    /* RIGHT SECTION */
    .library-component .right-section {
        width: calc(100% - 229px);
        padding: 33px 15px 50px 15px;
    }
}