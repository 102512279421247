/* SKIP TO */

.skip-to {
    background-color: #FFF;
    padding: 0px 18px;
    opacity: 0;
    /* height: 34px; */
    border: 1px solid #212529;
    position: absolute;
    top: -60px;
    left: 50%;
    z-index: 10001;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.skip-to a {
    font-size: .8rem;
    font-weight: 400!important;
    line-height: 1;
    /* color: #212529!important; */
    display: inline-block;
    text-decoration: underline;
}

.skip-to ul {
    padding: 0 0 4px 0;
    margin: 0!important;
}

.skip-to ul li::before {
    content: none;
}

.skip-to.show-skip-to {
    opacity: 1;
    top: 0;
}

nav p, .navbar-collapse p {
    margin-bottom: 0;
    line-height: 12px;
}

/* Override bootstrap hamburger menu */

nav {
    max-height: 95px;
}
.navbar-toggler {
    border: 1px solid transparent!important;
    padding-right: 0;
    padding-left: 0;
}

.navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url('../../assets/images/style/Icon-Menu@2x.png')!important;
    background-size: 18px;
}

.navbar-toggler .navbar-toggler-icon {
    background-image: url('../../assets/images/style/Icon-Remove-lg@2x.png')!important;
}

.navbar-toggler-icon {
    background-size: 15px;
}

.navbar-collapse {
    z-index: 100;
}

.navbar>.btn-nav-search {
    margin-top: 5px;
}

.navbar-collapse .btn-nav-search {
    margin-bottom: 7px;
    margin-left: 7px;
}

.nav-accent {
    border-bottom: 8px solid #047A9C;
}

.nav-accent-extra {
    /* position: absolute;
    bottom: -13px;
    left: 0; */
    display: block;
    width: 100%;
    border-bottom: 5px solid #DAF0ED;
}

.app-name {
    display: inline-block;
}

.brand .vertical-divider {
    margin-top: 6px;
    height: 13px;
}

.navbar-brand {
    width: 90px;
    margin-right: 22px;
    font-size: none;
    font-size: .75rem;
}

.navbar-brand img {
    width: 104px;
}

a.kitchen-manager, a.kitchen-manager:hover, a.kitchen-manager:focus, a.kitchen-manager:visited {
    padding: 0 10px 0 8px;
    font-size: .938rem;
    color: #333333;
    text-decoration: none;
    margin-top: 2px;
}

.vertical-divider {
    /* vertical-align: initial; */
}

.web-right-side-bottom .vertical-divider {
    margin-top: 10px;
}

.web-right-side-top .vertical-divider {
    display: none;
    vertical-align: middle;
    margin-top: 16px;
}

.menu-items .vertical-divider {
    /* margin-bottom: -3px; */
}

.btn-nav-search {
    width: 15px;
    height: 15px;
    /* display: inline-block!important; */
    /* override d-lg-block */
}

.username {
    color: #005A70!important;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    font-weight: 600;
}

/* Expanded Hamburger Navigation */

.navbar-collapse.show, .navbar-collapse.collapsing {
    position: absolute;
    top: 63px;
    left: 0;
    display: inline-block;
    background-color: #f8f9fa;
    width: 265px;
    text-align: left;
    padding: 20px 0px;
}

nav.nav-accent .collapse.show, nav.nav-accent .collapsing {
    top: 71px;
}

.menu-items {
    text-align: left;
}

.section-1 .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-nav.vertical-align {
    align-items: flex-start!important;
}

.section-1, .section-2, .section-3, .section-4 {
    padding: 7px 0;
}

.section-1 .nav-item, .section-1 .logout a {
    display: inline-block;
    /* line-height: 12px; */
    font-weight: 600;
    /* color: #005A70!important; */
}

/* remove custom bullet points */

nav ul, .navbar-collapse ul {
    padding-left: initial;
    list-style: none;
}

nav ul li, .navbar-collapse ul li {
    list-style: none;
}

nav ul li:before, .navbar-collapse ul li {
    content: none;
}

.navbar-collapse ul li::before {
    content: none;
}

.section-3, .section-4 {
    border-bottom: 1px solid #E9E9E9;
}

.section-3 a, .section-4 a {
    color: #252525 !important;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .844rem;
    font-weight: 600;
    line-height: 18px;
}

.section-3 ul {
    min-width: 260px;
}

.section-4 ul {
    min-width: 200px;
}

/* disable vertical align on mobile */

.section-1 ul {
    align-items: initial;
}

.section-1 .logout {
    width: 100%;
    text-align: right;
}

.section-1 .logout a {
    padding-top: 0px;
    line-height: 15px;
}

li.nav-item {
    width: auto;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

li.nav-item p {
    display: inline-block;
}

/* SEARCH BAR */

.top-search-form {
    display: none;
    position: absolute;
    top: 63px;
    right: 0;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    padding: 20px;
    width: 100%;
    z-index: 110;
}

nav.nav-accent {
    /* top: 71px; */
}

.btn.search-btn, .btn.search-btn:hover, .btn.search-btn:active {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 42px;
    height: 42px;
}


.top-search-form {
    text-align: left;
}

.top-search-form .accessibility-wrapper,
.top-search-form h4, .top-search-form .close-icon {
    display: inline-block;
}

.top-search-form .close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    vertical-align: top;
}


.search-btn img {
    width: 18px;
    margin-left: -5px;
    margin-top: -2px;
}

.top-search-form .dropdown select, .top-search-form .field-input input {
    height: 42px!important;
}

.top-search-form .field-input {
    position: relative;
    width: calc(100% - 42px);
}

.top-search-form .field-input, .top-search-form .search-btn {
    margin-top: 12px;
}

.top-search-form .field-input input {
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid transparent;
    border-left: 1px solid #C7C7C7;
    z-index: 101;
}

.top-search-form .dropdown {
    width: 100%;
}

.top-search-form .dropdown select {
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #C7C7C7;
    background-position-x: calc(100% - 10px);
    padding-right: 20px; /* IE caret overlap on focus */
}

.top-search-form .search-row {
    display: table;
}

.top-search-form .search-row button {
    border-radius: 0!important;
}

.top-search-form .search-row .dropdown {
    min-width: 155px;
}

.top-search-form .search-row>form>div, .top-search-form .search-row>form>.search-btn {
    /* display: inline-block; */
    display: table-cell;
    float: left;
}

.top-search-form .vertical-divider {
    position: absolute;
    top: 10px;
    left: 0;
    height: 22px;
}

/* SUB HEADER */

.sub-navbar {
    padding: 15px;
    border-bottom: 4px solid #DAF0ED;
}

.sub-navbar h1 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.813rem;
}

.sub-navbar .search-field {
    width: calc(100% - 42px);
}

.sub-navbar input {
    width: 100%;
    height: 42px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.navbar-brand .d-md-none {
    display: inline-block!important;
}

.navbar-brand .d-md-block {
    display: none!important;
}

@media (min-width: 827px) {
    .navbar-brand .d-md-none {
        display: none!important;
    }
    .navbar-brand .d-md-block {
        display: inline-block!important;
    }
    .navbar-brand img {
        width: 123px;
    }
    a.kitchen-manager, a.kitchen-manager:hover, a.kitchen-manager:focus, a.kitchen-manager:visited {
        font-size: 1.25rem;
        margin-top: 3px;
    }
}

@media (min-width: 768px) {
    /* Web and Mobile Content */
    .web {
        display: inline-block;
    }
    .mobile {
        display: none;
    }
    .menu-items {
        width: 100%;
        text-align: right;
    }
    .nav-link {
        display: inline-block;
    }
    .navbar-brand {
        width: auto;
        margin-right: 10px;
    }
    .section-1, .section-2, .section-3, .section-4 {
        display: inline-block;
    }
    .section-4 {
        margin-right: -22px;
    }
    li.nav-item {
        width: initial;
        text-align: initial;
    }
    .section-3 a, .section-4 a {
        text-align: right;
    }
    .section-3, .section-3 {
        border-bottom: 1px solid transparent;
    }
    .section-1, .section-2 {
        padding-top: 0px;
    }
    .section-3, .section-4 {
        padding-bottom: 0px;
        border-bottom: 1px solid transparent;
    }
    .section-1 .logout {
        width: auto;
    }
    .section-1 .logout a {
        padding-top: 8px;
        line-height: 28px;
    }
    li.nav-item {
        padding-left: 5px;
        padding-right: 5px;
    }
    .navbar-nav.vertical-align {
        align-items: center!important;
    }
    .web-right-side-top, .web-right-side-bottom {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        /* Center vertically */
        align-items: center!important;
        -ms-flex-align: center!important;
        justify-content: flex-end;
    }
    .web-right-side-top .vertical-divider {
        display: inline-block;
    }
    /* SEARCH BAR */
    .top-search-form {
        top: 50px;
        width: 577px;
    }
    .top-search-form .dropdown {
        width: 155px;
    }
    .top-search-form .dropdown select {
        width: 155px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0px solid transparent;
        background-position-x: 135px;
    }
    .top-search-form .field-input {
        width: 338px;
    }
    .top-search-form .field-input input {
        width: 338px;
        border-left: 0px solid transparent;
    }
    .top-search-form .field-input, .top-search-form .search-btn {
        margin-top: 0px;
    }
    .search-btn {
        margin-top: 0px;
    }
    .top-search-form .field-input input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    /* SUB HEADER */
    .sub-navbar {
        display: flex;
        padding-right: 30px;
        padding-left: 30px;
    }
    .sub-navbar h1 {
        margin-bottom: 0;
        text-align: left;
        display: inline-block;
        width: 385px;
    }
    .search-bar {
        width: 100%;
    }
    .brand .vertical-divider {
        margin-top: 6px;
        height: 15px;
    }
}