.menu-card .info {
    padding-top: 10px;
}

.menu-card .like-icon-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.menu-card .title {
    min-height: 78px;
}