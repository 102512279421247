div.line {
    margin: 25px 0;
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
}

div.dashed-line {
    margin: 25px 0;
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border-bottom: 1px dashed #C7C7C7;
}