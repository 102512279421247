.custom-checkbox {
    position: relative;
    margin-bottom: 7px;
    padding-right: 15px;
    width: fit-content;
}

.custom-checkbox:not:last-child {
    margin-bottom: 0;
}

.custom-checkbox label {
    vertical-align: top;
}

.custom-checkbox .checkbox-normal:focus {
    outline: 1px solid transparent;
}

.custom-checkbox .checkbox-normal, .custom-checkbox .checkbox-normal:hover, .custom-checkbox .checkbox-normal:focus {
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 4px 3px 5px 4px!important;
    height: 8px;
    width: 8px;
    vertical-align: top;
    /* -moz-appearance: none; */
}

.custom-checkbox label {
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    line-height: 16px;
    margin-left: 5px;
    width: calc(100% - 30px);
}

.custom-checkbox label::before, .custom-checkbox label.unselected::before {
    /* content: ''; */ /* for group checkbox commented */
    background: url('../../assets/images/components/Checkbox-Unselected-Normal@2x.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 20px;
    display: inline-block;
    background-size: 19px 16px;
    padding: 0 0 0 0px;
}

.custom-checkbox .checkbox-normal:focus+label::before {
    background: url('../../assets/images/components/Checkbox-Unselected-Focused@2x.png');
    background-size: 19px 16px;
}

.custom-checkbox .checkbox-normal:disabled+label::before {
    background: url('../../assets/images/components/Checkbox-Unselected-Disabled@2x.png');
    background-size: 19px 16px;
}

.custom-checkbox label.selected::before, .custom-checkbox .checkbox-normal:checked+label::before {
    background: url('../../assets/images/components/Checkbox-Selected-Normal@2x.png');
    background-size: 19px 16px;
}

.custom-checkbox .checkbox-normal:checked:focus+label::before {
    background: url('../../assets/images/components/Checkbox-Selected-Focused@2x.png');
    background-size: 19px 16px;
}

.custom-checkbox .checkbox-normal:checked:disabled+label::before {
    background: url('../../assets/images/components/Checkbox-Selected-Disabled@2x.png');
    background-size: 19px 16px;
}