#home-page html, #home-page body {
    height: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

#home-page p, #home-page a, #home-page h1 {
    -webkit-text-stroke: 0px;
}

#home-page .main-error {
    margin-top: 10px;
	background-color: rgba(255,255,255,.75);
	display: inline;
	padding-left: 5px;
	padding-right: 5px;
}

#home-page .container {
    max-width: 758px;
}

#home-page #section-1 {
    /* min-height: 100vh; */
    background: url('../../assets/images/home-banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#home-page .homepage-header {
    margin-top: 50px;
}

#home-page .homepage-header p {
    font-family: 'OpenSans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-weight: 600;
    font-size: 0.875rem;
}

#home-page .homepage-header a {
    font-family: 'OpenSans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    font-weight: 600;
    display: inline-block;
}

#home-page .homepage-header h1 {
    color: #007FA3;
    margin-bottom: 20px;
    font-size: 2.063rem;
}

#home-page .homepage-header img {
    width: 92px;
    margin-bottom: 20px;
}

#home-page .homepage-header input::placeholder {
    color: #C7C7C7;
    font-weight: 0;
    -webkit-text-stroke: 0px;
    opacity: 1;
}

#home-page .homepage-header .error {
    margin-bottom: 10px;
}

#home-page .homepage-header .forgot-password {
    margin-bottom: 30px;
}

#home-page .homepage-header .field-input input, .homepage-header button, .homepage-header button:hover {
    width: 200px;
}

#home-page .homepage-header button {
    margin-bottom: 10px;
}

#home-page #section-2 {
    padding: 30px 0;
    min-height: 100px;
}

#home-page #section-2 p {
    padding-right: 0px;
    margin-bottom: 25px;
    line-height: 19px;
}

#home-page #section-2 a {
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    border-left: none;
    text-align: center;
    line-height: 20px;
}

#home-page #section-2 .right {
    padding-left: 0;
}

#home-page #section-3 {
    text-align: center;
}

#home-page #section-3 h1 {
    color: white;
}

#home-page #section-3 p {
    font-family: "OpenSans";
    font-size: 1rem;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
}


#home-page #section-4 {
    display: none;
    background-color: #DAF0ED;
    text-align: center;
    position: relative;
    height: 600px;
}

#home-page #section-4 h2 {
    margin-bottom: 45px;
}



#home-page #section-4 .key-features-img-container {
    position: absolute;
    top: 150px;
    left: 50%;
    margin-left: -700px;
    width: 1400px;
    height: 380px;
    margin-top: 10px;
}

#home-page #section-5 h3 {
    color: #005A70;
    margin-bottom: 20px;
}

#home-page #section-5 ul {
    list-style: square url("../../assets/images/style/BulletPoint.png");
    padding-left: 37px;
    margin-bottom: 35px;
}

#home-page #section-6 {
    padding-top: 50px;
}

#section-6 a {
    color: #FFF;
}

#home-page #section-6 .support {
    margin-bottom: 50px;
}

#home-page #section-6 .support p {
    font-family: "OpenSans";
    font-weight: 300;
    font-size: .844rem;
}

#home-page #section-6 .legal-row p, #section-6 a {
    font-family: "OpenSans";
    font-weight: 100;
    font-size: .844rem;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
}

#home-page #section-6 .legal-row .second-row p {
    display: inline-block;
}

@media (min-width: 576px) {
    #home-page .homepage-header {
        margin-top: 50px;
    }
    #home-page .homepage-header img {
        margin-bottom: 30px;
    }
    #home-page #section-2 p {
        margin-bottom: 0;
    }
    #home-page #section-2 a {
        border-left: 1px solid #fff;
    }
}

@media (min-width: 768px) {
    #home-page .homepage-header {
        margin-top: 125px;
    }
    #home-page .homepage-header img {
        margin-bottom: 55px;
    }
    #home-page #section-4 {
        display: block;
    }
}

@media (min-width: 992px) {
    #home-page #section-1 h1 {
        font-size: 2.688rem;
    }
}

@media (min-width: 1200px) {
    #home-page #section-1 h1 {
        font-size: 3.875rem;
    }
    #home-page #section-1 img {
        width: 99px;
    }
}

/* Force bigger size of tablets with bigger heights */

@media only screen and (min-width: 768px) and (min-height: 900px) {
    #home-page #section-1 h1 {
        font-size: 3.875rem;
    }
    #home-page #section-1 img {
        width: 99px;
    }
}