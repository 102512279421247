.search-results-info {
    color: #252525;
}

.search-results-info .search-query {
    color: #005A70;
}

.search-results-info .close-icon {
    width: 16px;
    cursor: pointer;
}