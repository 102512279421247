.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 1 !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"!important;
    filter: alpha(opacity=100)!important;
}
.gu-mirror {
    overflow: visible;
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,.1)!important;
  }

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit.item {
    opacity: 1!important;
}

.gu-transit .item {
    /* opacity: 1!important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    border-top: 1px solid #E9E9E9!important; */
}

.gu-transit .name,
.gu-transit .amount {
    opacity: 0; 
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}

.gu-mirror.item, .gu-mirror .item, .gu-mirror.sub-list > .name {
    border-bottom: 1px solid #E9E9E9!important;
    background-color: white;
}

.gu-mirror .drag-handle {
    display: inline-block;
}