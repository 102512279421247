.branding-header {
    margin-top: 30px;
    margin-bottom: 30px;
}
.branding-header .navbar-brand img {
    width: 104px;
}
.branding-header .kitchen-manager, .branding-header .kitchen-manager:hover, .branding-header .kitchen-manager:focus, .branding-header .kitchen-manager:visited {
    padding: 0 10px 0 8px;
    font-size: .938rem;
    color: #333333;
    text-decoration: none;
    margin-top: 2px;
    margin-bottom: 0;
    font-weight: 600;
}

.branding-header .vertical-divider {
    /* margin-left: 10px; */
    /* margin-right: 10px; */
}

