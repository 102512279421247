.field-input label {
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .813rem;
    font-weight: 600;
    line-height: 16px;
    display: block;
}

.field-input input, .field-input textarea {
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-weight: normal;
    line-height: 16px;
    text-indent: 0px;
    padding-left:5px;
}

.dropdown.dropdown-lg-basic select:focus {
    outline: 0px solid transparent;
    border: 1px solid #047A9C;
}

.field-input textarea {
    padding-top: 10px;
    resize: none;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #C7C7C7!important;
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: lighter;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #C7C7C7!important;
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: lighter;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #C7C7C7!important;
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: lighter;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #C7C7C7!important;
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: lighter;
}

:placeholder {
    color: #C7C7C7!important;
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: lighter;
}

.field-input input:focus {
    outline: 0px solid transparent;
    border: 1px solid #047A9C;
}

.field-input input:invalid {
    border: 1px solid #DB0020;
}

.field-input input:disabled {
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #F5F5F5;
}

.input-lg input {
    height: 43px;
    width: 153px;
    font-size: .875rem;
}

.input-sm input {
    height: 33px;
    width: 153px;
    font-size: .75rem;
}

.field-input input.error {
    border: 1px solid #DB0020;
}

.field-input p.error {
    color: #DB0020;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    line-height: 16px;
}

.dropdown label {
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .813rem;
    font-weight: 600;
    line-height: 16px;
    display: block;
}

.dropdown select::-ms-expand {
    display: none;
}

.dropdown select {
    position: relative;
    height: 43px;
    width: 153px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    background-color: #FFFFFF;
    text-indent: 8px;
    background: url(../../assets/images/style/Icon-Caret-Down@2x.png) no-repeat right #ddd;
    background-position-x: calc(100% - 10px);
    background-size: 10px 10px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.dropdown option {
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 18px;
    border-radius: 3px;
    background-color: #F9F9F9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.dropdown option:checked::before {
    content: '><';
    display: inline-block;
}

@media not all and (min-resolution:.001dpcm) {
    @media {
        .field-input input {
            line-height: normal;
        }
    }
}