.awesomplete mark {
    background: transparent !important;
    padding: 0!important;
}
.awesomplete li {
    list-style: none!important;
}
.awesomplete li:before {
    content: '';
}

.ingredient-name .awesomplete {
    width: 100%;
}