.filter-badge-collection:not(.no-mobile-scrolling) {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    padding: 5px 0 6px 0;
}

/* Because no display flex will add spacing between divs */
.filter-badge-collection.no-mobile-scrolling .filter-badge {
    margin-right: -2px!important;
    margin-bottom: 4px;
}

.filter-badge-collection .filters {
    display: inline-block;
    flex: 0 0 auto;
	max-width: calc(100% - 100px);
}

.filter-badge-collection::-webkit-scrollbar {
    display: none;
}

.filter-badge-collection .filter-badge {
    display: inline-block;
    border-radius: 4px;
    background-color: #F5F5F5;
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    line-height: 15px;
    margin-bottom: 5px;
    padding: 5px 10px 5px 15px;
    text-align: left;
}

.filter-badge-collection .filter-badge {
    min-height: 35px;
    margin-right: 2px;
    margin-bottom: 3px;
}

.filter-badge-collection .collection-title {
    display: inline-block;
    margin-right: 15px;
    white-space: nowrap;
    margin-bottom: 0;
    margin-top: 4px;
    font-size: 0.75rem;
}

.filter-badge-collection .group-name {
    display: inline-block;
    margin-right: 10px;
}

.filter-badge-collection .filter {
    display: inline-block;
    color: #005A70;
    font-weight: 600;
    font-size: .75rem;
    padding: 5px 10px 5px 0;
}

.filter-badge-collection .filter .filter-close {
    cursor: pointer;
    width: 13px;
    height: 14px;
    margin-bottom: 2.5px;
    margin-left: 5px;
}
.filter-badge-collection button {
    width: 54px;
    padding-left: 0;
    padding-right: 0;
}

@media only screen and (min-width: 768px) {
    .filter-badge-collection:not(.no-mobile-scrolling)  {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        padding: 0;
    }
    .filter-badge-collection .filter-badge {
        margin-bottom: 4px;
    }
    .filter-badge-collection button {
        text-transform: initial;
    }
}

/* For horizontal scrolling */

@media only screen and (max-width: 767px) {
    .filter-badge-collection:not(.no-mobile-scrolling) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
    }
    .filter-badge-collection .filter-badge {
        flex: 0 0 auto;
    }
}