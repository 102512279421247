/* JP LIST */

.jplist-panel {
    display: block !important;
    height: 36px;
}

.jplist-panel ul li::before {
    content: none;
}

.jplist-pagingmid {
    display: none;
}

.jplist-no-results, 
.jplist-none-created {
    display: none;
    margin-top: 40px;
    text-align: center;
}

.jplist-no-results h3 {
    margin-bottom: 30px;
}

.jplist-no-results strong {
    display:inline-block;
    color: #252525;
    font-family: 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: 0.875em;
    font-weight: 600;
    margin-bottom: 13px;
}

.jplist-no-results p {
    margin-bottom: 0;
    text-align: center;
    font-size: 0.813em;
    line-height: 16px;
}

/* JP LIST GENERAL */

.jplist-panel label,
.jplist-select,
.jplist-pagination,
.jplist-label,
.jplist-drop-down,
.jplist-panel button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.jplist-panel label,
.jplist-select,
.jplist-pagination,
.jplist-label,
.jplist-drop-down,
.jplist-panel button {
    background: transparent !important;
    border: none !important;
}

.jplist-panel button {
    text-shadow: 0px 0px 0px transparent;
}

/* JPLIST SHOW QTY DROPDOWN */

#items-per-page.jplist-drop-down {
    width: 44px !important;
    margin-right: 0px !important;
}

.jplist-drop-down .jplist-dd-panel {
    width: auto;
    margin-right: 0px !important;
    padding-right: 18px !important;
    padding-left: 0 !important;
    margin-left: -7px !important;
}

.jplist-dd-panel::after {
    content: none !important;
}

.jplist-dd-panel::-ms-expand {
    display: none;
}

.jplist-dd-panel {
    padding: 0 5px !important;
    background: url(../../assets/images/style/Icon-Caret-Down@2x.png) no-repeat right !important;
    background-size: 10px 10px !important;
    background-color: transparent;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
}

#items-per-page.jplist-drop-down .jplist-dd-panel {
    width: auto;
    /* padding-right: 10px!important; */
    margin-right: 0px !important;
    margin-left: -8px !important;
}

#sort.jplist-drop-down .jplist-dd-panel {
    margin-right: 0px !important;
    /* background-position-x: 110px!important; */
}

.jplist-drop-down ul {
    float: right;
    overflow: visible;
}

.jplist-drop-down ul li {
    font-size: .75rem;
}

.jplist-panel .items-per-page-group {
    float: right !important;
    /* margin-right: -12px; */
}

.jplist-drop-down .jplist-dd-panel {
    line-height: 31px !important;
}

/* JPLIST SORT BY DROPDOWN */

/* .jplist-select::-ms-expand {
    display: none;
} */

.jplist-panel #label-sort {
    padding-left: 0;
}

/* JPLIST TOP AND BOTTOM PANELS */

.jplist-panel-top {
    padding-bottom: 4px;
    border-bottom: 1px dashed #C9C9C9;
}

.jplist-panel-bottom {
    padding-top: 4px;
    border-top: 1px dashed #C9C9C9;
}

/* JPLIST PAGINATION */

.jplist-panel-top .jplist-label,
.jplist-panel-top .jplist-pagination {
    display: none;
}

.jplist-panel-bottom .jplist-label,
.jplist-panel-bottom .jplist-pagination {
    text-align: center;
    width: 100%;
}

.jplist-panel-bottom .jplist-pagination {
    padding-left: 8px;
}

.jplist-panel-bottom .jplist-panel-bottom button {
    margin-right: 11px !important;
}

.jplist-pagination {
    margin-top: 6px !important;
}

.jplist-pagingnext {
    padding-left: 14px;
	position: relative;
	border-left: 1px solid !important;
	color: #D9D9D9;
}

.jplist-pagingprev {
    /*margin-right: 7px;*/
	width: 30px;
}

.jplist-pagingprev,
.jplist-pagingnext {
    display: inline-block !important;
}

/* Hide default < & > arrows placed by jplist */

.jplist-pagination button {
    cursor: pointer;
}

.jplist-pagination .jplist-prev,
.jplist-pagination .jplist-prev:hover,
.jplist-pagination .jplist-next,
.jplist-pagination .jplist-next:hover {
    color: transparent;
}

.jplist-pagination .jplist-prev {
    height: 18px;
    background: url('../../assets/images/style/Icon-Chevron-Left-lg.png') no-repeat !important;
}

.jplist-pagination .jplist-next {
    height: 18px;
    background: url('../../assets/images/style/Icon-Chevron-Right-lg.png') no-repeat !important;
}

.jplist-first,
.jplist-last {
    display: none;
}

@media only screen and (min-width: 768px) {
    /* SHOW BY QTY DROPDOWN */
    .jplist-panel .items-per-page-group {
        float: left !important;
        margin-right: 0;
    }
    .jplist-select {
        margin-right: 70px !important;
    }
    .jplist-drop-down ul {
        float: left;
    }
    /* JPLIST PAGINATION */
    .jplist-panel-top .jplist-label,
    .jplist-panel-top .jplist-pagination,
    .jplist-panel-bottom .jplist-label,
    .jplist-panel-bottom .jplist-pagination {
        margin-right: 10px !important;
		display: flex;   
  		justify-content: flex-end; 
    }
    .jplist-panel-bottom .jplist-label,
    .jplist-panel-bottom .jplist-pagination {
        text-align: initial;
        width: auto;
    }
	.jplist-panel-top .jplist-label,
	.jplist-panel-bottom .jplist-label{
		position: absolute;
		right: 100px;
	}
}

/* JPLIST FONTS */

.jplist-panel {
    color: #252525 !important;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace !important;
    font-size: .75em !important;
    line-height: 16px !important;
}

.jplist-drop-down,
.jplist-select,
.jplist-select option {
    font-weight: 600;
    color: #005A70 !important;
}

/* Component */

.pagination-component {
    width: 100%;
    /* margin-top: 20px; */
}

/* LIST (CARDS CONTAINER) */

/* For testing */

.list .tags {
    color: red;
}

.list {
    padding: 15px 0px;
    text-align: center;
}

.list.custom-container {
    margin: 0 auto;
    width: fit-content;
    /* max-width: 1200px; */
}

.list::before {
    content: '';
    display: block;
    clear: both;
}

/* RECIPE AND MENU CARDS */

/* FORCE TO SMALL VERSION ON ALL WIDTHS */

.list .card.recipe-card,
.list .card.menu-card {
    width: 300px;
    height: 405px;
}

.list .card.recipe-card .card-img,
.list .card.menu-card .card-img {
    width: 288px;
    height: 288px;
}

.list .card .picture-frame-img .frame-1 .img-container .frame-img {
    width: 262px;
    height: 262px;
}

@media only screen and (min-width: 576px) {
    .list {
        /* padding: 15px 30px 15px 30px; */
        text-align: left;
    }
    .list .card.recipe-card,
    .list .card.menu-card {
        width: 230px;
        height: 336px;
    }
    .list .card.recipe-card .card-img,
    .list .card.menu-card .card-img {
        width: 218px;
        height: 218px;
    }
    .list .card .picture-frame-img .frame-1 .img-container .frame-img {
        width: 192px;
        height: 192px;
    }
}

/* Card Padding */

.list .card {
    margin: 0 5px;
    margin-bottom: 10px;
}

.list .recipe-card p, .list .menu-card p, .list .recipe-card div, .list .menu-card div {
    font-weight: 500;
    color: #212529;
}

.list .card.book-card {
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: 18px;
    margin-right: 18px;
}

.list .line {
    border: 1px dashed transparent;
    border-bottom: 1px dashed #C9C9C9;
    margin-top: 10px;
    margin-bottom: 10px;
}

.list .list-item {
    display: inline-block;
}

/* This is for JS purposes go to services/pagination/ui.js */

.list a.custom-container-child {
    width: 300px;
}

@media only screen and (min-width: 768px) {
    .jplist-no-results {
        margin-top: 60px;
    }
}

@media only screen and (min-width: 576px) {
    .list a.custom-container-child {
        width: 240px;
    }
}

/* SAFARI */

@media not all and (min-resolution:.001dpcm) {
    @media {
        .jplist-panel-bottom .jplist-pagination {
            margin-left: 4px;
        }
        /* CSS overrides for mobile here */
        .jplist-pagingnext::before {
            left: -24.5px;
        }
    }
}

/* iPhone only */

@media only screen and (max-device-width: 480px) {
    /* .jplist-panel-bottom .jplist-pagination {
        margin-left: 4px;
    } */
    /* CSS overrides for mobile here */
    .jplist-pagingnext::before {
        /* left: -44px; */
    }
}