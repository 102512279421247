/* FOR TESTING ONLY START */

body {
    /* margin: 100px 0; */
}

body>div>h2, body>div>h3, body>div>h4 {
    /* margin-top: 35px; */
}

strong {
    /* display: block; */
}

.checklist-dev-only {
    /* display: inline-block;
    width: 120px;
    border: 1px solid grey; */
}

.like-bg {
    /* display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-align: middle;
    height: 75px;
    width: 300px;
    background-color: #333333; */
}

.filter-badge-collection {
    /* width: 450px; */
}

/* FOR TESTING ONLY END */





