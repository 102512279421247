.container {
    max-width: 758px;
}

.dropdown select {
    /* background-position-x: 39px; */
}

.header-section {
    margin-top: 50px;
    margin-bottom: 45px;
}

.header-section h1 {
    margin-bottom: 10px;
    font-size: 1.813rem;
}

.header-section h1.edit {
    margin-bottom: 10px;
    font-size: 1.668rem;
    margin-bottom: 25px;
}

.header-section .recipe-reference a {
    font-size: 0.8125rem;
}

.header-section a.recipe-book {
    padding-right: 2px;
}

.header-section a.recipe-chapter-page {
    padding-left: 2px;
}

.header-section a.recipe-book, .header-section a.recipe-chapter-page {
    font-weight: 500;
}

.share-section {
    margin-bottom: 15px;
}

.share-section a {
    font-family: "OpenSans";
    font-size: .813rem;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
}

.share-section .vertical-divider {
    margin-right: 18px;
    margin-left: 20px;
    width: 1px;
}

.share-section .mobile-menu {
    justify-content: center;
}

.share-section .mobile-menu .vertical-divider {
    margin-top: 6px;
}

.share-section .mobile-menu a {
    display: inline-block;
}

.share-section .mobile-menu .btn {
    padding: 0;
    width: auto;
}

.share-section .mobile-menu .c-dropdown-menu {
    display: inline-block;
}

.share-section .mobile-menu .dropdown-content {
    left: initial;
    right: 0;
}

.product-img {
    margin-bottom: 15px;
    /*max-height: 483px;
    overflow: hidden;*/
}

.product-img img {
    width: 100%
}

/* .product-controls {
    margin-bottom: 40px;
} */

/* TOP PRODUCT CONTROLS */

.product-controls-top {
    max-width: 350px;
    margin: auto;
}

.product-controls-top button {
    width: 32%!important;
}


.product-controls-top .c-dropdown-menu {
    width: 32%!important;
}

.product-controls-top .c-dropdown-menu .btn {
    width: 100%!important;
}

.product-controls::after {
    content: '';
    display: block;
    clear: both;
}

.product-controls>.btn, .product-controls .c-dropdown-menu {
    display: inline-block;
    /* float: left; */
}

.product-controls .like-btn,
.product-controls .edit-btn {
    width: 95px!important;
}

.product-controls .save-as-btn {
    width: 95px!important;
}

.product-controls .add-to-btn {
    width: 185px!important
}

.product-controls .add-to-shopping-btn {
    width: 165px!important;
}

.product-controls .more-btn, .product-controls .c-dropdown-menu, .product-controls .c-dropdown-menu .btn {
    width: 95px!important;
}

.product-controls .c-dropdown-menu .btn {
    border-radius: 4px;
    color: #252525;
    border: 1px solid #C7C7C7;
}

.product-controls .c-dropdown-menu .dropdown-content {
    left: -84px;
    right: 0;
}

.product-controls-bottom a {
    text-decoration: none;
}

.measurement-controls {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 45px;
    border-bottom: 1px dashed #C9C9C9;
}

/* Measurement System Header */

.measurement-controls .radio-normal+label {
    margin-left: 0;
}

.measurement-controls .measurement-system {
    border-bottom: 1px dashed #C9C9C9;
    display: flex;
    width: 100%;
    flex-flow: wrap;
}

.measurement-controls .measurement-system h4,.measurement-controls .measurement-system .h2 {
    margin-bottom: 15px;
}

.measurement-controls .measurement-system h4, .measurement-controls .measurement-system .system-selection>div,
.measurement-controls .measurement-system > div, .measurement-controls .measurement-system .h2 {
    display: inline-block;
}
.measurement-controls .measurement-system > div{
    flex: 0 0 60%;
}

.measurement-controls .measurement-system .system-selection {
    display: block;
    float: none;
    flex: 0 0 20%;
}

.measurement-controls .american-radio, .measurement-controls .metric-radio {
    margin-right: 75px;
}

@media (min-width: 576px) {
    .measurement-controls .measurement-system .system-selection {
        float: right;
    }

}
@media (max-width: 576px) {
    .measurement-controls .measurement-system > div {
    flex: 0 0 100%;
}
@media (max-width: 375px) {
  .measurement-controls .measurement-system .system-selection {
    flex: 0 0 100%;
}
}
}
/* Measurement System Bottom */

.measurement-bottom {
    padding-top: 6px;
    padding-bottom: 9px;
}

.measurement-bottom p {
    margin-bottom: 0;
}

.measurement-bottom .title {
    font-weight: 600;
    margin-bottom: 0px;
    font-size: .813rem;
}

.measurement-bottom .input-row {
    vertical-align: bottom;
}

.measurement-bottom .dropdown {
    vertical-align: top;
    margin-top: -5px;
    margin-left: 16px;
}

.measurement-bottom .field-input, .measurement-bottom input {
    width: 56px!important;
}
.measurement-bottom .field-input, .measurement-bottom input, .mesaurement-bottom .dropdown, .measurement-bottom .dropdown select {
    min-height: 33px;
    vertical-align: middle;
}



.mesaurement-bottom .dropdown, .measurement-bottom .dropdown select {
    border: none;
    color: #005A70;
    font-family: "OpenSans";
    font-size: .75rem;
    font-weight: 600;
    line-height: 17px;
    text-indent: .1rem;
}

.measurement-bottom .measurement-group {
    margin-bottom: 5px;
}

.measurement-bottom .yield div, .measurement-bottom .serving-size div, .measurement-bottom .servings > div {
    display: inline-block;
}

.measurement-bottom .accesibility-wrapper {
    display: block !important;
}

.yield select {
    width: 60px!important;
}

#serving-unit-select,
#yield-unit-select {
    width: 94px!important;
}

.measurement-bottom .serving-size .size {
    padding-top: 3px;
}
.menu-section .ingredients-list {
    margin-bottom: 50px;
}

.menu-edit-section {
    margin-bottom: 45px;
}

.nutrition-facts-section {
    margin-bottom: 45px;
}

.nutrition-facts-section .key {
    font-weight: 600;
}

.ingredients-section, .directions-section, .related-videos-section, .nutrition-facts-section {
    margin-bottom: 45px;
}

.directions-section li {
    padding-bottom: 15px;
}

.directions-section ul {
    padding-left: 40px !important;
}

.directions-section ul.no-bullets {
    padding-left: 10px !important;
}

.directions-section ul li {
    list-style: disc;
}
.directions-section ul.no-bullets li {
    list-style: none;
}

.related-videos-section .related-video {
    margin-bottom: 5px;
}

.related-videos-section a {
    font-weight: 400;
    font-size: 0.9375rem;
}

.badge-section {
    width: 100%;
    text-align: center;
    margin-bottom: 70px;
    margin-top: 45px;
}

.badge-section .filter-badge .group-name {
    font-weight: 500;
}

@media (min-width: 768px) {
    .product-controls .like-btn,
    .product-controls .edit-btn {
        width: 113px!important;
    }
    .product-controls .save-as-btn {
        width: 129px!important;
    }
    .product-controls .add-to-btn {
        width: 189px!important;
    }
    .product-controls .add-to-shopping-btn {
        width: 165px!important;
    }
    .product-controls .more-btn, .product-controls .c-dropdown-menu, .product-controls .c-dropdown-menu .btn {
        width: 107px!important;
    }
}

/* MOZILLA ONLY */

@-moz-document url-prefix() {
    #dropdown-1 {
        width: 85px;
    }
    .dropdown select {
        /* background-position-x: 50px; */
    }
}