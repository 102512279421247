.card {
    display: inline-block;
    height: 336px;
    width: 230px;
    padding: 5px;
    border: 1px solid #E9E9E9;
    background-color: #FFFFFF;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    margin-bottom: 15px;
    position: relative;
    vertical-align: top;
}

.card p {
    margin-bottom: 0;
}

.card .info {
    padding: 0 5px;
}

.card .title {
    padding: 10px 0;
    border-bottom: 1px solid #C7C7C7;
    font-size: .875rem;
    line-height: 19px;
}

.card .card-img {
    width: 218px;
    height: 218px;
    border-radius: 0;
}

.card .detail {
    padding: 5px 0;
    font-size: .719rem;
    line-height: 15px;
}

@media only screen and (min-width: 576px) {
    .card {
        height: 405px;
        width: 300px;
    }
    .card .card-img {
        width: 288px;
        height: 288px;
    }
}