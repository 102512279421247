.modal .item {
    padding: 0!important;
    margin: 0!important;
}

.modal form {
    width: 100%;
}

.modal .group {
    margin-bottom: 30px;
}

.modal.info-modal {
    background-color: rgba(255, 255, 255, 1);
    padding-right: 0!important;
}

.modal.info-modal .field-input {
    vertical-align: top;
}

.modal.info-modal p {
    font-size: .875rem;
    line-height: 19px;
}

.modal.info-modal input[type="text"], .modal.info-modal textarea {
    width: 100%;
}

.modal textarea {
    font-size: .875rem;
}

.modal.info-modal textarea {
    height: 90px;
}

.info-modal .modal-dialog {
    width: 100%;
    min-height: 100vh;
    max-width: none;
    margin: 0;
}

.info-modal .modal-content {
    border: none;
    border-radius: 0;
    background-color: #FFFFFF;
    position: relative;
    padding: 100px 0px 85px 0;
}

button.modal-close {
    position: fixed;
    top: 25px;
    right: 25px;
    opacity: 1;
}

button.modal-close img {
    width: 24px;
}

.info-modal .modal-header, .info-modal .modal-footer {
    display: inline-block;
    text-align: center;
    border-bottom: none;
    border-top: none;
}

.info-modal .modal-header {
    padding: 0;
    padding-right: 1rem;
    padding-left: 1rem;
}

.info-modal .fixed-bottom-dest-2 {
    border-top: 1px solid #D9D9D9;
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: 150;
    background-color: #ffffff;
    padding: 1rem 0;
}

.info-modal strong {
    font-size: .875rem;
    font-weight: 600;
}

.info-modal .modal-footer {
    margin-top: 75px;
    border-top: 1px solid #D9D9D9;
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: 150;
    background-color: #ffffff;
}

.info-modal .line-input {
    width: 49%;
    display: inline-block;
}

.modal-container .modal-title {
    padding-bottom: 15px;
    margin-bottom: 35px;
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    top: -12px;
    position: fixed;
    padding-top: 15px;
    /* z-index: 150; */
    background-color: #ffffff;
    text-align: center;
    left: 0;
}

.info-modal .top-content {
    /* margin-top: 100px; */
}

.info-modal .modal-footer button, .info-modal .modal-footer button:hover {
    margin-right: 0px!important;
    margin-left: 0px!important;
}

.info-modal .modal-footer.buttons-2 button, .info-modal.modal-footer .buttons-2 button:hover {
    max-width: 181px;
    width: 48%;
}

.info-modal .modal-footer.buttons-3 button, .info-modal.modal-footer .buttons-3 button:hover {
    max-width: 143px;
    width: 34%;
}

.info-modal .modal-footer .btn-lg-link {
    width: 16%!important;
    min-width: 65px;
}

.info-modal .amount {
    margin-bottom: 5px;
}

.modal-container .fixed-bottom-dest-2 {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1100;
}

/* SPECIAL MODALS */

/* ADD TO MENU */

.modal.add-to-menu-modal .custom-checkbox {
    width: 100%;
}

.modal.add-to-menu-modal label {
    font-weight: 600;
    font-size: .813rem;
}

.modal.add-to-menu-modal select, .modal.add-to-menu-modal input[type="text"] {
    margin-left: 29px;
    width: calc(100% - 30px)!important;
}

/* ADD RECIPE */
#modal-menu-add-recipe .awesomplete,
#modal-menu-edit-recipe .awesomplete {
    width: 100%;
}

.awesomplete > ul, .awesomplete > ul {
    z-index: 151;
}

/* SHOPPING LIST MODAL */

.modal-shopping-list .ingredient-group {
    margin-bottom: 30px;
}

.info-modal .input-sm {
    max-width: 92px;
}

.info-modal.cost-modal strong {
    margin-bottom: 7px;
    display: inline-block;
}

.info-modal.cost-modal .dashed-line {
    margin-top: 15px;
    margin-bottom: 20px;
}

.info-modal.cost-modal .line {
    margin-top: 0;
}

.info-modal.cost-modal .calculations-2 p {
    display: inline-block;
}

.cost-modal .ingredient-list strong {
    font-size: 0.8125rem;
}

.cost-modal .item div {
    font-size: 0.9375rem;
}

.info-modal .print {
    width: 100%;
    max-width: 180px;
}

.info-modal .number-of-servings input {
    width: 50px!important;
}

/* Share Recipe */
.modal-share-recipe .modal-body .field-input,
.modal-share-recipe .modal-body input {
    width: 100%!important;
}
.modal-share-recipe textarea {
    height: 151px;
}
.modal-share-recipe .top-content p {
    text-align: center;
}
.modal-share-recipe .top-content .field-input {
    width: 100%!important;

}
.modal-share-recipe .top-content input {
    width: calc(100% - 85px)!important;
}
.modal-share-recipe .top-content .btn-copy {
    width: 80px;
}

@media only screen and (min-width: 768px) {
    .modal-container .fixed-bottom-dest-2 {
        display: none;
    }
    .modal-dialog {
        height: 100%;
    }
    .modal.info-modal {
        background-color: rgba(255, 255, 255, .98);
        padding-right: 17px!important;
        margin-top: 0;
        margin-bottom: 0;
    }
    .info-modal .modal-content {
        border: 2px solid #D9D9D9;
        padding: 15px 0px 30px 0;
        margin: auto;
        /* margin-top: auto;
        margin-bottom: 30px; */
    }
    .modal-container .modal-title {
        padding-bottom: 0px;
        margin-bottom: 15px;
        border-bottom: none;
        position: relative;
    }
    .info-modal .top-content {
        margin-top: 0px;
    }
    .info-modal .modal-header, .info-modal .modal-footer {
        display: inline-block;
        /* padding: 15px 20px 0 20px; */
    }
    .info-modal .line-input {
        width: fit-content;
    }
    .info-modal .modal-dialog {
        /* min-height: 418px; */
        width: 621px;
        max-width: none;
        margin: 1.75rem auto;
    }
    .info-modal .modal-footer {
        position: relative;
        margin-top: 0px;
        border-top: none;
    }
    .info-modal .modal-footer.buttons-3 button, .info-modal.modal-footer .buttons-3 button:hover {
        max-width: 143px;
        width: 42%;
    }

    /* Share Recipe */
    .modal-share-recipe .top-content .field-input {
        padding-left: 15px;
        padding-right: 15px;
    }
}