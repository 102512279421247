.btn-lg-link {
    box-sizing: border-box;
    height: 43px;
    width: 153px;
    color: #005A70;
    background-color: #FFFFFF;
}

.btn-sm-link {
    box-sizing: border-box;
    height: 35px;
    width: 153px;
    color: #005A70;
    background-color: #FFFFFF;
}