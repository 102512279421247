@media (min-width: 992px) {
    .filter-toggle {
        display: none;
    }
}

.filters-component {
    display: none;
    position: absolute;
    top: 0;
    min-height: 100vh;
    width: 100%;
    border-top: 1px solid #E9E9E9;
    z-index: 100;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 152px;
    padding-top: 60px;
}

.filters-component .filter-divider {
    height: 8px;
    background-color: #FBFBFB;
    border-bottom: 1px solid #E9E9E9;
}

/* Get rid of border right so parent component can take care of this for all its children */

.filters-component .category-group:first-child {
    border-top: 1px solid #E9E9E9;
}
.filters-component .category-group {
    border: 1px solid #E9E9E9;
    border-right: none;
    border-top: none;
}



.filters-component .collapse-ctrl, .filters-component .collapse-content {
    border: none;
}
.filters-component .collapse-ctrl[aria-expanded="true"] {
    border-bottom: 1px solid #E9E9E9;
}

/* offset checkbox margin bottom on mobile */

.filters-component .collapse-content {
    padding: 12px 20px 0 20px;
}

.filters-component .rectangle {
    padding: 0 20px;
}

.filters-component #liked-filter-container {
    width: auto;
}

.filters-component .liked-filter {
    margin: 30px 20px 0px 20px;
    min-width: 110px;
}
.filters-component .liked-filter img {
    width: 14px;
}

.filters-component .liked-filter label {
    margin-top: 1px;
    margin-bottom: 0;
}

.filters-component h3 {
    margin: 15px 0;
}

.filters-component .filter-group h4 {
    margin-top: 25px;
    margin-bottom: 15px;
}

.filters-component .header {
    width: 100%;
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    position: fixed;
    top: 0;
    background-color: #FFF;
    width: 100%;
    z-index: 1050;
}

.filters-component .header h3 {
    display: inline-block;
    margin: auto;
    font-size: 1.813rem;
    padding: 10px 0;
}

.filters-component .header .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 18px;
    /* margin-left: auto; */
}

.filters-component .web-controls {
    position: absolute;
    top: -27px;
    left: 20px;
}

.filters-component .web-controls p,
.filters-component .web-controls .accessiblity-wrap {
    display: inline-block;
    font-size: .75rem;
}

.filters-component .web-controls .btn-sm {
    margin-left: 55px;
    width: fit-content;
    text-transform: none;
    background-color: transparent;
    width: auto;
    height: auto;
}

.filters-component .mobile-controls {
    margin-top: 50px;
    padding: 15px 0;
    border: 1px solid #E9E9E9;
    position: fixed;
    bottom: 0;
    background-color: #FFF;
    width: 100%;
}

.filters-component .mobile-controls .results {
    font-weight: 600;
}

@media (min-width: 992px) {
    .filters-component {
        display: inline-block!important;
        /* override jquery hide */
        min-height: initial;
        position: relative;
        width: 227px;
        position: relative;
        overflow-y: visible;
        padding-bottom: 0;
        padding-top: 0;
    }
    .filters-component .header {
        position: relative;
    }
    .filters-component .collapse-content {
        padding: 0 0 0 20px;
    }
    .filters-component .custom-checkbox:first-child {
        margin-top: 15px;
    }
    .filters-component .collapse-content {
        padding: 0 0 0 20px;
    }
}