.custom-radio {
    position: relative;
}

.radio-normal:focus {
    outline: 1px solid transparent;
}

.radio-normal {
    border-radius: 10px;
    border: 1px solid transparent;
    margin: 10px 4px 0px 4px!important;
    height: 10px; /* for group css 5px to 10 px */
    width: 13px;
    vertical-align: top;
    /* -moz-appearance: none; */
}

.radio-normal label {
    vertical-align: top;
}

.radio-normal+label {
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    line-height: 16px;
    margin-left: 5px;
    width: calc(100% - 35px);
}

.radio-normal+label::before {
    /* content: ''; */ /* for group css commented */
    background: url('../../assets/images/components/Radiobutton-Unselected-Normal@2x.png');
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 5px;
    height: 18px;
    width: 18px;
    background-size: 18px 18px;
    display: inline-block;
    padding: 0 0 0 0px;
}

.radio-normal:focus+label::before {
    background: url('../../assets/images/components/Radiobutton-Unselected-Focused@2x.png');
    background-size: 18px 18px;
}

.radio-normal:disabled+label::before {
    background: url('../../assets/images/components/Radiobutton-Unselected-Disabled@2x.png');
    background-size: 18px 18px;
}

.radio-normal:checked+label::before {
    background: url('../../assets/images/components/Radiobutton-Selected-Normal@2x.png');
    background-size: 18px 18px;
}

.radio-normal:checked:focus+label::before {
    background: url('../../assets/images/components/Radiobutton-Selected-Focused@2x.png');
    background-size: 18px 18px;
}

.radio-normal:checked:disabled+label::before {
    background: url('../../assets/images/components/Radiobutton-Selected-Disabled@2x.png');
    background-size: 18px 18px;
}