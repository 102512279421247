/* Stick to Bottom Trick */
#main {
    box-sizing: border-box;
    min-height: 100%;
    padding: 0 0 100px;
    /* position: relative; */
}

footer {
    position: absolute;
    bottom: 0;
    height: auto;
    left: 0;
    width: 100%;
}

/* Footer Styles */
footer {
    padding: 45px 0 70px;
    background-color: #333333;
}

footer .container {
    max-width: 758px;
}

footer ul {
    padding-left: 0;
}
footer ul li::before {
    content: none;
}

footer .container a, footer .container a:hover, footer p, footer span {
    color: #FFF;
    -webkit-text-stroke: 0;
    font-size: .844rem;
    line-height: 18px;
    font-weight: 400;
}

footer .container a, footer p, footer span {
    margin-bottom: 0;
}

footer span {
    margin-left: 8px;
    margin-right: 8px;
}

footer .top-links {
    margin-bottom: 40px;
}

footer .top-links a {
    display: inline-block;
    margin-bottom: 20px;
}

footer .right-links, footer .left-links {
    margin-bottom: 5px;
}

footer .right-links a, footer .right-links a:hover {
    font-weight: 300;
}

footer .legal-row a,
footer .legal-row p {
    line-height: 18px;
}