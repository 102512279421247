.alert-modal {
    text-align: center;
    margin-top: 100px;
}

.alert-modal {
    padding-right: 0!important;
}

.alert-modal p {
    line-height: 19px;
}

.alert-modal .modal-content {
    padding: 35px 0;
}

.alert-modal .modal-header, .alert-modal .modal-footer {
    display: inline-block;
}

.alert-modal .modal-header {
    border-bottom: none;
}

.alert-modal .modal-footer {
    border-top: none;
}

.alert-modal .modal-footer button, .alert-modal .modal-footer button:hover {
    margin-right: 0px!important;
    margin-left: 0px!important;
    max-width: 181px;
    width: 48%;
}

@media only screen and (min-width: 768px) {
    .alert-modal {
        margin-top: 0;
    }
}