.library-component .create-recipe-section {
    display: inline-block;
}

.library-component .create-recipe-section.empty {
    display: none;
}

@media only screen and (min-width: 992px) {
    .library-component .create-recipe-section.empty {
        display: block;
    }
    .library-component .create-recipe-section.empty {
        height: 26px!important;
    }
}
