.glossary {
    max-width: 758px;
}

.glossary h1 {
    margin-top: 40px;
    font-size: 1.8125rem;
    margin-bottom: 25px;
}

.placeholder, .item-placeholder {
    display: none;
}

/* SEARCH BAR */

.glossary .search-controller .search-bar {
    margin-bottom: 40px;
}

.glossary .search-controller .search-bar a {
    display: inline-block;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .813rem;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    padding-right: 16px;
    padding-left: 10px;
    margin-bottom: 13px;
}

.glossary .search-controller .search-bar a.separator-right {
    padding-right: 15px;
    /* color: red; */
    border-right: 1px solid #E9E9e9;
}

.glossary .search-controller .search-bar {
    position: relative;
    border-bottom: 1px dashed #E9E9E9;
    padding-bottom: 10px;
    text-align: center;
}

/* SEARCH RESULTS */

.glossary .search-controller .search-results .letter-content {
    margin-bottom: 20px;
}

.glossary .search-controller .search-results .letter-content a {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 18px;
}

.glossary .search-controller .term .name {
    font-weight: 600;
}

/* Collapse */

.glossary .collapse-ctrl {
    border: none;
    border-bottom: 1px solid #E9E9E9;
    padding: 0!important;
}

.glossary .collapse-content {
    border: none;
}

.glossary .collapse.show {
    display: flex;
}

.glossary .collapse-ctrl img {
    margin-bottom: 20px;
}

.glossary .video-item {
    font-size: 0.875rem;
}

@media (min-width: 576px) {
    .glossary .collapse-content {
        padding: 0;
    }
    .glossary .search-controller .collapse {
        display: flex;
    }
    .glossary .search-controller .search-results .letter-content:not(:last-child) {
        border-bottom: 1px solid #E9E9E9;
    }
    .glossary .search-controller .search-results .letter-content {
        margin-bottom: 25px;
        padding-bottom: 20px;
    }
}