.item, .item.gu-mirror {
    /* -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000; */
}

/* ITEMS W LISTS FOR ACCESIBILITY */

.item li {
    width: 66.66%;
    list-style: none;
}

.item li .name {
    width: 100%!important;
}

.ingredients-list .item, .item.gu-mirror {
    display: block;
    position: relative;
}



.ingredients-list .item .drag-handle, .item.gu-mirror .drag-handle {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    /* background-image: url("/app/theme/public/build/assets/images/style/Icon-Sort@2x.png"); */
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    touch-action: none;
}

.ingredients-list .item .edit-toggle, .item.gu-mirror .edit-toggle {
    cursor: pointer;
}

.ingredients-list .item .field-input, .item.gu-mirror .field-input {
    display: inline-block;
}

.ingredients-list .item .field-input input, .item.gu-mirror .field-input input {
    font-size: .875rem;
}

.item:not(.sub-list), .gu-mirror:not(.sub-list) {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* Center vertically */
    align-items: center!important;
    -ms-flex-align: center!important;
}

.ingredients-list .item::after, .item.gu-mirror::after {
    content: '';
    display: block;
    clear: both;
}

.ingredients-list.edit .item, .item.gu-mirror {
    border-top: 1px solid #E9E9E9;
}

.item:not(.sub-list), .item.gu-mirror:not(.sub-list) {
    padding: 10px 20px 10px 0;
}

.ingredients-list .item.sub-list>.name, .item.gu-mirror.sub-list>.name, .ingredients-list .item.sub-list>li .name, .item.gu-mirror.sub-list>li .name {
    min-height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ingredients-list .item a, .item.gu-mirror a {
    font-weight: 400;
    font-size: .875rem;
}

.ingredients-list .item p, .item.gu-mirror p {
    margin-bottom: 0;
    line-height: 25px;
    /* line-height: 40px */
}

.ingredients-list .item p,
.gu-mirror.item p {
    font-size: 0.875rem;
}

.ingredients-list .item .name p, .ingredients-list .item .name a, .item.gu-mirror .name p, .item.gu-mirror .name a {
    /* max-width: calc(100% - 40px); */
}

.ingredients-list .item .name p, .ingredients-list .item .name a, .ingredients-list .item .name .field-input, .item.gu-mirror .name p, .item.gu-mirror .name a, .item.gu-mirror .name .field-input {
    display: inline-block;
    padding-left: 10px;
}

.ingredients-list .item p, .item.gu-mirror p, .ingredients-list .item img, .item.gu-mirror img {
    display: inline-block;
}

.ingredients-list .item img, .item.gu-mirror img {
    width: 20px;
}

.ingredients-list .sub-list p, .sub-list.gu-mirror p {
    line-height: 25px;
}

.ingredients-list .sub-list .item .name, .sub-list.gu-mirror .item .name {
    padding-left: 20px;
}

.ingredients-list>.nested>.item:last-child, .nested>.item.gu-mirror:last-child {
    border-bottom: 1px solid #E9E9E9;
}

.ingredients-list .item:not(.sub-list) .name, .ingredients-list .item .amount, .item.gu-mirror:not(.sub-list) .name, .item.gu-mirror .amount {
    float: left;
}

.ingredients-list .item .sub-name, .item.gu-mirror .sub-name {
    float: none;
    font-weight: 600;
}

.ingredients-list .item .name, .item.gu-mirror .name {
    width: 66.66%;
}

.ingredients-list .sub-list .item {
    /* width: calc( 100% + 20px); */
}

.ingredients-list .sub-list .nested.force-sublist-height, .sub-list.gu-mirror .nested.force-sublist-height {
    min-height: 47px;
}

.ingredients-list .item .amount, .item.gu-mirror .amount {
    width: 33.33%;
}

.ingredients-list .item .edit-toggle, .item.gu-mirror .edit-toggle {
    position: absolute;
    right: 0;
    top: 10px;
    /* float: right;
    margin-top: 10px; */
}

/* Ingredients list Controls */

.ingredients-list .controls {
    padding: 20px 0;
    text-align: center;
}

.ingredients-list .controls button {
    height: 33px;
    width: 49%;
    max-width: 181px;
    margin-right: 0px!important;
    margin-left: 0px!important;
}

/* .sub-list .item {
    padding-left: 15px;
} */

@media only screen and (min-width: 768px) {
    .ingredients-list .item:not(.sub-list), .item.gu-mirror:not(.sub-list) {
        padding: 10px 20px 10px 0;
    }
    .ingredients-list .item .name p, .ingredients-list .item .name a, .ingredients-list .item .name .field-input, .item.gu-mirror .name p, .item.gu-mirror .name a, .item.gu-mirror .name .field-input {
        display: inline-block;
        padding-left: 20px;
    }
}

/* MENU LIST */

.ingredients-list {
    position: relative;
}

.ingredients-list.menu .item, .ingredients-list.menu .sub-list>li .name {
    min-height: 50px;
}

.ingredients-list.menu h3 {
    margin-bottom: 20px;
}

.ingredients-list.menu  p.error {
    display: block;
}

.ingredients-list.menu h3, .ingredients-list.menu p {
    display: inline-block;
}

.gu-mirror .name,
.ingredients-list .name {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center!important;
    -ms-flex-align: center!important;
}

.ingredients-list.menu .header h3 {
    margin-top: 20px;
}

.ingredients-list.menu .header .accessibility-wrapper,
.ingredients-list.menu .header p {
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: auto;
    max-width: 59px;
    text-align: right;
    font-size: 0.8125rem;
}
/* .ingredients-list.menu .header .accessibility-wrapper {
    margin-left: auto;
} */
.ingredients-list.menu .header .accessibility-wrapper p {
    margin-top: 0;
    vertical-align: top;
}

.ingredients-list .placeholder {
    display: none!important;
}

.placeholder, .placeholderNoClick {
    display: none!important;
}

.gu-mirror.menu-item .name:not(.sub-name) p, .gu-mirror.menu-item .name:not(.sub-name) a,
.ingredients-list.menu .item .name:not(.sub-name) p, .ingredients-list.menu .item .name:not(.sub-name) a {
    /* line-height: 80px; */
    font-weight: 600;
    /* max-width: calc(100% - 80px); */
}

.gu-mirror.item .name .recipe-img,
.ingredients-list.menu .item .name .recipe-img {
    width: 75px;
    height: 50px;
}

.gu-mirror.menu-item .amount .edit-toggle,
.ingredients-list.menu .item .amount .edit-toggle {
    top: 0;
    right: 0;
    position: relative;
}

.gu-mirror.item .amount .edit-toggle, .ingredients-list.menu .item .amount .edit-toggle, .ingredients-list.menu .item .amount p {
    float: right;
    text-align: right;
}
.ingredients-list.menu .item .amount p {
    margin-left: auto;  
}


.ingredients-list.menu .sub-list .item {
    width: 100%;
}

.ingredients-list.menu .item {
    padding: 15px 0;
}

/* EDIT VERSION */

.ingredients-list.menu.edit .item .name .recipe-img {
    display: none;
}

.gu-mirror .name .recipe-img,
.ingredients-list.menu.edit .name .recipe-img {
    padding-left: 10px;
}

.gu-mirror.menu-item .amount p,
.ingredients-list.menu.edit .item .amount p,
.ingredients-list.menu.edit .item .amount > div {
    padding-right: 14px;
    margin-left: auto;
}

.ingredients-list.menu.edit .item .name:not(.sub-name) p, .ingredients-list.menu.edit .item .name:not(.sub-name) a {
    max-width: 100%;
}

.ingredients-list.menu .sub-list .nested.force-sublist-height {
    min-height: 81px;
}

@media (min-width: 578px) {
    .ingredients-list.menu .header .accessibility-wrapper,
    .ingredients-list.menu .header p {
        max-width: fit-content;
        text-align: left;
    }
}
@media (min-width: 768px) {
    /* EDIT VERSION */
    .ingredients-list.menu.edit .item .name .recipe-img {
        display: inline-block;
    }
}

/* Ingredients List Drag and Drop - Keyboard Controls */

.ingredients-list .accessibility-controls {
    /* display: none; */
    position: absolute;
    top: 47px;
    left: 0px;
    background-color: #FFF;
    width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-bottom: 15px;
}

.ingredients-list.menu .accessibility-controls {
    top: 57px;
    left: 0px;
}

.ingredients-list .accessibility-controls .nested p {
    padding-left: 30px!important;
}

.ingredients-list .accessibility-controls .header {
    width: 315px;
}

.ingredients-list .accessibility-controls .header h3 {
    margin-top: 0;
    max-width: 70%;
}

.ingredients-list .accessibility-controls .close-icon {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}

.ingredients-list .accessibility-controls .item-accessible {
    position: relative;
    padding: 0px 10px;
    /* border-top: 1px solid #E9E9E9; */
}

.ingredients-list .accessibility-controls .item-accessible:last-child {
    border-bottom: 1px solid #E9E9E9;
}

.ingredients-list .accessibility-controls h3, .ingredients-list .accessibility-controls .item-accessible p.name-accessible {
    display: block;
    padding: 0px 15px;
    margin-bottom: 0;
    font-size: .75rem;
}

.ingredients-list .accessibility-controls h3 {
    font-size: 1rem;
    color: #212529;
    padding-top: 15px;
    display: inline-block;
}

.ingredients-list .accessibility-controls .position-buttons {
    /* display: inline-block; */
    padding: 15px;
}

.ingredients-list .accessibility-controls .position-buttons button {
    font-size: .6rem;
    height: 30px;
    width: 50px;
}

@media (min-width: 576px) {
    .ingredients-list.menu .accessibility-controls {
        left: 80px;
    }
    .ingredients-list.recipe .accessibility-controls {
        left: 80px;
    }
}

@media (min-width: 768px) {
    .ingredients-list.recipe .accessibility-controls {
        left: 150px;
    }
    .ingredients-list.menu .accessibility-controls {
        top: 80px;
        left: 100px;
    }
    .gu-mirror.menu-item {
        padding: 10px 0px 10px 0!important;
    }
}