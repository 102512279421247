.event-details-section .date {
    margin-bottom: 15px;
}

.sub-header {
    height: 24px;
}

.header-section p, .event-details-section p {
    font-size: .813rem;
}

.event-details-section .date h3 {
    margin-bottom: 0;
}

.event-details-section .date p {
    margin-bottom: 0;
}

.event-details-section .col-4:not(:last-child) {
    border-right: 1px dashed #C9C9C9;
}

.event-details-section .location {
    margin-bottom: 30px;
}

.event-details-section .location .address {
    display: inline-block;
    font-size: .813rem;
}

.event-details-section .location .location-icon {
    width: 15px;
}

.product-controls {
    margin-bottom: -7px;
}