/* Fonts Import */

/* OPEN SANS */

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-LightItalic.ttf");
    font-weight: 300;
    font-style: italic;
}

/* 400 */

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-Italic.ttf");
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-SemiBoldItalic.ttf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-ExtraBold.ttf");
    font-weight: 800;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-ExtraBoldItalic.ttf");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSans-SemiBoldItalic.ttf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Playfair Display";
    src: url("../fonts/PlayfairDisplay-Regular.ttf");
}

@font-face {
    font-family: "Playfair Display";
    src: url("../fonts/PlayfairDisplay-Italic.ttf");
    font-style: italic;
}

/* @font-face {
    font-family: "Playfair Display";
    src: url("../fonts/PlayfairDisplay-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Playfair Display";
    src: url("../fonts/OpenSans-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Playfair Display";
    src: url("../fonts/PlayfairDisplay-Black.ttf");
    font-weight: 900;
}

@font-face {
    font-family: "Playfair Display";
    src: url("../fonts/OpenSans-BlackItalic.ttf");
    font-weight: 900;
    font-style: italic;
} */