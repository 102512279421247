 @media print {
 
     p {
         margin-bottom: 0px;
     }
   body, body p, body a {
        /* font-weight: 100!important; */
        -webkit-text-stroke: 0px!important;
        -webkit-print-color-adjust: exact;
        overflow: visible;
        overflow-x: visible;
    }

    .modal-close {
        display: none;
    }

    .modal {
        position:relative;
        margin-top: 50px!important;
        margin-bottom: 10px!important;
        overflow:visible;
    }

    input {
        border: 1px transparent solid!important;
        padding: 0!important;
        text-indent: 0!important;
    }

    .info-modal .modal-dialog {
        height: 418px!important;
        /* width: 621px!important; */
        max-width: none!important;
        margin: 1.75rem auto!important;
    }

    .modal-dialog-centered {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: start!important;
    }
}