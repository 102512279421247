.c-dropdown-menu {
    position: relative;
}

/* LINK STYLE MENU INSTEAD OF BOX */
.c-dropdown-menu button,
.c-dropdown-menu button:hover,
.c-dropdown-menu button:active {
    color: #005A70;
    background-color: transparent;
    border: none;
    border-radius: 0;
}

.c-dropdown-menu .dropdown-content {
    display: none;
    position: absolute;
    left: -3px;
    background-color: #FFFFFF;
    min-width: 180px;
    overflow: auto;
    border: 1px solid #C7C7C7;
    z-index: 1;
    overflow: visible;
    /*padding: 0px 12px;*/
}

.c-dropdown-menu .dropdown-content a {
    padding: 16px 0px;
    text-decoration: none;
    display: block;
    height: 51px;
    color: #252525;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    border-bottom: 1px solid #E9E9E9;
}

.c-dropdown-menu .dropdown-content a:hover {
    /*height: 51px;	*/
    background-color: #F5F5F5;
}

.c-dropdown-menu .caret {
    width: 12px;
    margin-bottom: 2.5px;
    margin-left: 3px;
}