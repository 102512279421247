/* 
    MAP 
    # BROWSER SPECIFIC
    # LAYOUT
    # SKIN
    # ELEMENTS
    # COLORS
    # VIEW
    # BEHAVIOR
    # DIVIDERS
*/

/* # BASE FONT */


/* CHROME ONLY CSS */

/* Fixes font weight inconsistency issues */

@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
    html {
        -webkit-text-stroke: .2px;
    }
    .sub-note {
        -webkit-text-stroke: 0px;
    }
    p {
        -webkit-text-stroke: .1px;
    }
}

/* Locks screen - No scrolling allowed. Apple to body (good for mobile) */

.lock-screen {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

/* ACCESIBILITY */

/* FIREFOX - Fix dropdown dashed outline not matching with element w and h */

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

/* # LAYOUT */

.section {
    padding: 75px 0;
}

.block {
    display: block!important;
}
.inline-block {
    display: inline-block;
}

.center {
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: center;
}

.force-center {
    width: fit-content;
    margin: auto;
}

.full-width {
    width: 100%!important;
}

.width-initial {
    width: initial!important;
}

.vertical-align {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* Center vertically */
    align-items: center!important;
    -ms-flex-align: center!important;
}

.no-ver-m {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.m-bottom-sm {
    margin-bottom: 15px;
}

.m-bottom-md {
    margin-bottom: 30px;
}

.z-max {
    z-index: 99999999;
}

.pull-right {
    float: right;
}


/* # SKIN */

.hidden {
    display: none!important;
}

.sub-note, .optional {
    font-weight: 300;
    color: #6A7070;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .875rem;
    line-height: 19px;
}

.link-btn,
.link-btn:hover {
    text-decoration: none;
}



/* #VIEW */

/* Web and Mobile Content */

.web {
    display: none;
}

.mobile {
    display: inline-block;
}

.print-only {
    display: none!important;
}

@media print {
    .print-only {
        display: block!important;
    }
    .print-hidden {
        display: none!important;
    }
    body {
        -webkit-text-stroke: 0px;
    }
	.product-img img {
        max-height: 250px;
        max-width: 250px;
    }
}

@media (min-width: 768px) {
    /* Web and Mobile Content */
    .web {
        display: inline-block;
    }
    .mobile {
        display: none;
    }
}

/* # BEHAVIOR */

.pointer {
    cursor: pointer;
}

/* # DIVIDERS */

.vertical-divider {
    display: inline-block;
    width: 1px;
    height: 15px;
    border-right: 1px solid #D9D9D9;
    height: 13px;
    margin-bottom: 0;
    margin-bottom: 4px;
    vertical-align: bottom;
}

/* Sticky elements */

.sticky {
    position: fixed!important;
    top: 0;
 }

 p.error {
    color: #DB0020;
    font-family: "OpenSans", 'Open Sans', Calibri, Tahoma, sans-serif, Monaco, 'Lucida Console', monospace;
    font-size: .75rem;
    line-height: 16px; 
 }

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and ( -o-min-device-pixel-ratio: 2/1),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx)
{  
    .info-modal .modal-footer { position: relative; }
    #modal-add-ingredient > div > form > div > div.modal-footer.buttons-2,
    #modal-create-sub-list > div > form > div > div.modal-footer.buttons-2,
    #modal-menu-add-recipe > div > form > div > div.modal-footer.buttons-2,
    #modal-recipe-add-to-my-menus > div > form > div > div.modal-footer.buttons-3,
    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3,
    #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3, 
    #saveAs-modal-button-container
     {
        bottom:0px;
     }
    #modal-recipe-edit-ingredient > div > form > div > div.modal-header > div > div.dashed-line
     {
        margin-top:15px; 
        margin-bottom:15px;
     }

    #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3
    {
           top:65vh;
    }

    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3
     {
       top:40vh;
     }

     #modal-edit-sublist > div > form > div > div.modal-footer.buttons-3
     {
        top:40vh;
     }

}

@media only screen and (min-width: 541px) {
    

     #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3
    {
        top: unset;
        bottom:0 !important;
    }

    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3
     {
        top: unset;
        bottom:0 !important;
     }

     #modal-edit-sublist > div > form > div > div.modal-footer.buttons-3
     {
        top: unset;
        bottom:0 !important;
     }

}


/* Samsung Galaxy S6, S6+, S7, S7+ | 1440×2560 pixels landscape ----------- */
@media only screen and (min-device-width: 360px)
and (max-device-height: 640px)
and (orientation : landscape)
and (-webkit-device-pixel-ratio: 4) {
    .info-modal .modal-footer { position: fixed; }
    #modal-add-ingredient > div > form > div > div.modal-footer.buttons-2, 
    #modal-create-sub-list > div > form > div > div.modal-footer.buttons-2,
    #modal-menu-add-recipe > div > form > div > div.modal-footer.buttons-2,
    #modal-recipe-add-to-my-menus > div > form > div > div.modal-footer.buttons-3,
    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3,
    #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3, 
    #saveAs-modal-button-container
     {
        top: unset;
        bottom:0 !important;
     }

     #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3
    {
        top: unset;
        bottom:0 !important;
    }

    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3
     {
        top: unset;
        bottom:0 !important;
     }

     #modal-edit-sublist > div > form > div > div.modal-footer.buttons-3
     {
        top: unset;
        bottom:0 !important;
     }
}

/*iphone 6/7/8 portrait */
@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 667px) 
and (orientation : portrait) {
    .info-modal .modal-footer { position: fixed; }
    #modal-add-ingredient > div > form > div > div.modal-footer.buttons-2, 
    #modal-create-sub-list > div > form > div > div.modal-footer.buttons-2,
    #modal-menu-add-recipe > div > form > div > div.modal-footer.buttons-2,
    #modal-recipe-add-to-my-menus > div > form > div > div.modal-footer.buttons-3,
    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3,
    #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3, 
    #saveAs-modal-button-container
    {
        bottom:90px;   

    }
}

/*iphone 6/7/8 landscape */
@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 667px) 
and (orientation : landscape) {
    body.modal-open { overflow: scroll !important; }
    body.modal-open #modal-add-ingredient.modal.fade.info-modal.ingredient-modal.show{ overflow-y: scroll; }
}


/*iphone 6+/7+/8+ portrait */
@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) 
and (orientation : portrait) { 
    .info-modal .modal-footer { position: fixed; }
    #modal-add-ingredient > div > form > div > div.modal-footer.buttons-2, 
    #modal-create-sub-list > div > form > div > div.modal-footer.buttons-2,
    #modal-menu-add-recipe > div > form > div > div.modal-footer.buttons-2,
    #modal-recipe-add-to-my-menus > div > form > div > div.modal-footer.buttons-3,
    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3,
    #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3, 
    #saveAs-modal-button-container
    {
        bottom:90px;
    }
}

/*iphone 6+/7+/8+ landscape */
@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) 
and (orientation : landscape) {
    body.modal-open { overflow: scroll !important; }
    body.modal-open #modal-add-ingredient.modal.fade.info-modal.ingredient-modal.show{ overflow-y: scroll; }
}

/*iphone X portrait */
@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 812px) 
and (-webkit-device-pixel-ratio : 3)
and (orientation : portrait) {
    .info-modal .modal-footer { position: fixed; border:1px solid #0f0; }
    #modal-add-ingredient > div > form > div > div.modal-footer.buttons-2, 
    #modal-create-sub-list > div > form > div > div.modal-footer.buttons-2,
    #modal-menu-add-recipe > div > form > div > div.modal-footer.buttons-2,
    #modal-recipe-add-to-my-menus > div > form > div > div.modal-footer.buttons-3,
    #modal-menu-edit-recipe > div > form > div > div.modal-footer.buttons-3,
    #modal-recipe-edit-ingredient > div > form > div > div.modal-footer.buttons-3, 
    #saveAs-modal-button-container
    {
        bottom:120px;
    }
}



div.accessibility-controls
{
    padding-bottom:5px !important;
}

div.accessibility-controls > img.close-icon
{
    width:15px;
    height:15px;

}

div.accessibility-controls >  div.header
{
    border-bottom: 1px rgb(139, 139, 139) solid;
    width:250px !important;
    max-width:250px !important;
    margin-top:-10px;
   /* margin-bottom:5px;*/

}


div.accessibility-controls >  div.item-accessible
{
    border-bottom: 1px rgb(219, 219, 219) solid;
    width:250px !important;
    max-width:250px !important;

}


div.accessibility-controls >  div.item-accessible > p.name-accessible
{
    line-height: 18px;
    padding-bottom:4px !important;
    padding-top:4px !important;
}


#overlay {
    background-color: rgba(200, 200, 200, 0.8);
    z-index: -999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
}​