/* Directions Section TINYMCE */

/* Text Box */
#mceu_11 {
    border: 1px solid #C7C7C7!important;	
    border-radius: 4px!important;
}

#directions_ifr {
    /* height: 251px!important; */
}

.mce-top-part::before, .mce-tinymce {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}

.mce-panel, #mceu_5-body {
    outline: 0px solid transparent!important;
    border: 0px solid transparent!important;
}

.mce-ico {
    color: #000000!important;
}

.mce-btn button {
    padding: 4px 12px!important;
}

.mce-flow-layout-item.mce-last {
    /* height: 18px!important; */
}

.mce-toolbar .mce-btn-group:first-child {
    padding-right: 10px!important;
}

.mce-toolbar .mce-btn-group:last-child {
    padding-left: 10px!important;
    /* height: 14px!important; */
    /* margin-top: 6px; */
}

.mce-toolbar .mce-btn-group:last-child .mce-btn button {
    /* margin-top: -6px!important; */
}

.mce-btn.mce-active, .mce-btn.mce-active:hover, .mce-btn.mce-active:focus, .mce-btn.mce-active:active {
    background: #e6e6e6!important;
}

/* Control Bar */
/* #mceu_8.mce-stack-layout-item {
    margin-bottom: 15px!important;
    border: 1px solid #E9E9E9;
    padding-top: 7px;
    padding-bottom: 7px;
} */


/* Control Buttons */
/* .mce-btn button {
    height: 30px!important;
} */

/* Active Control Button */
/* .mce-btn.mce-active:hover {
    border-color: #e2e4e7;

} */
/* .mce-btn.mce-active:hover,
.mce-btn.mce-active  {
    background: #ffffff!important;
} */

/* Active Control Icon */
.mce-active .mce-ico::before {
    color: #04719c!important;
}

/* Placeholder for top controls when they're sticky */
/* .top-control-placeholder {
    width: 100%;
    height: 67px;
} */

